<template>
  <div class="fill-heigt" fluid>
    <v-row class="px-0">
      <v-col cols="11" md="7">
        <div class="text-left black--text" style="margin-top: 3%">Ranking</div>
      </v-col>
      <v-col cols="1" md="1" class="text-right px-0">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
        <!-- >>.  <pre>{{ item_id }}</pre> -->
        <!-- <v-btn @click="vacancyFetch()">fetch</v-btn> -->
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-autocomplete
            v-model="vacancyId"
            item-text="title"
            item-value="id"
            :search-input.sync="search"
            cache-items
            hide-no-data
            :items="vacancies"
            :loading="loading"
            outlined
            :label="$t('vacancy')"
            dense
            hide-details
            @change="changeVacancy()"
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>

    <v-row class="mt-5" no-gutters>
      <v-col cols="12" md="8" class="text-left">
        <v-btn
          v-bind="attrs"
          v-on="on"
          v-if="accessLevel(currentUser, 'schedule_interview')"
          :disabled="!isSelected"
          color="primary"
          @click="dialogRejectAprove = true"
          style="margin-left: 5px"
          class="mx-3"
        >
          <span>
            {{ $t("aprove_or_reject") }}
          </span>
        </v-btn>
        <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'schedule_interview')"
                :disabled="!isSelected"
                color="primary"
                @click="dialogInterview = true"
              >
                <v-icon left>mdi-calendar</v-icon>
              </v-btn>
            </template>
            <div v-html="scheduleInterviewBtnLabel"></div>
          </v-tooltip>
        </template>

        <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'send_test')"
                :disabled="!isSelected"
                @click="dialogTest = true"
                style="margin-left: 5px"
                color="primary"
              >
                <v-icon left>mdi-send</v-icon>
              </v-btn>
            </template>
            <div v-html="sendTestBtnLabel"></div>
          </v-tooltip>
        </template>

        <v-btn
          style="display: none"
          v-if="accessLevel(currentUser, 'schedule_interview')"
          :disabled="!isSelected"
          color="primary"
          @click="dialogInterview = true"
        >
          <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right px-0 mx-6" style="width: 93.8%">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchCandidate')"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-0">
      <v-col cols="12" md="12">
        <!-- <v-card v-if="$apollo.queries.vacancies.loading">
          <v-skeleton-loader
            v-bind="filteredItems"
            :type="
              `table-thead, table-row-divider@${filteredItems.length}, table-tfoot`
            "
          ></v-skeleton-loader>
        </v-card> -->
        <v-card>
          <v-data-table
            v-model="selectedCandidates"
            :headers="headers"
            :items="filteredItems"
            :search="textSearch"
            show-select
            show-expand
            expand-icon="mdi-eye"
            color="primary"
            class="elevation-1"
            dense
            single-expand
            :loading="loading"
            :no-data-text="$t('tooltip_ranking')"
          >
            <template v-slot:item.photo="{ item }">
              <v-avatar size="40px" style="margin-left: -60%">
                <img
                  :src="
                    item.candidate.photo
                      ? `${apiUrl}/images/candidate/${item.candidate.photo}`
                      : `/avatar.png`
                  "
                  alt="John"
                />
              </v-avatar>
            </template>
            <template v-slot:item.fullName="{ item }">
              <span class="text-caption">{{ item.candidate.fullName }}</span>
            </template>
            <template v-slot:item.profession="{ item }"
              ><span class="text-caption">{{
                item.candidate.profession
                  ? item.candidate.profession.substring(0, 150)
                  : ""
              }}</span></template
            >
            <template v-slot:item.experience="{ item }"
              ><span class="text-caption"
                >{{ days(item.candidate.professionalExperience) }}
                {{ $t("year")
                }}{{
                  days(item.candidate.professionalExperience) > 1 ? "s" : ""
                }}</span
              ></template
            >
            <template v-slot:item.punctuation="{ item }">
              <!-- <pre>{{ removeDuplicates(item.tests).length }}</pre> -->
              <v-progress-linear
                v-if="item.tests.length > 0"
                :value="calculePontuation(item.tests)"
                :color="setColor(calculePontuation(item.tests))"
                height="16"
                readonly
                :indeterminate="item_id === item.id ? item_loading : false"
              >
                <span class="text--white">
                  {{
                    !item_loading ? calculePontuation(item.tests) : ""
                  }}%</span
                >
              </v-progress-linear>
            </template>
            <template v-slot:item.review="{ item }">
              <template>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="primary"
                      @click="
                        reviewTest(item.tests);
                        item_id = item.id;
                      "
                      >mdi-clipboard-text-search</v-icon
                    >
                  </template>
                  <div v-html="$t('tooltip_ranking_review')"></div>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:item.email="{ item }">
              <template>
                <v-icon v-on="on" color="primary" @click="openDialogEmail(item)"
                  >mdi-email</v-icon
                >
              </template>
            </template>
            <template v-slot:item.notes="{ item }">
              <v-icon v-on="on" color="primary" @click="openNoteDialog(item)"
                >mdi-comment-edit</v-icon
              >
            </template>
            <!-- <template v-slot:item.profile="{ item }">
              <v-btn
                color="blue"
                x-small
                height="30px"
                class="ma-1 pa-0"
                dark
                @click="go(item.candidate.id)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <!-- <v-row class="mx-1 mt-2 mb-0">
                      <v-spacer></v-spacer>
                  <v-btn color="primary" small @click="showProfile = !showProfile">
                    <v-icon small>{{ !showProfile? 'mdi-eye': 'mdi-trending-up'}}</v-icon> 
                    <span class="text-capitalize" v-show="!showProfile">{{ $t('form_candidate_profile') }}</span>
                  </v-btn>
                </v-row> -->
                <v-row class="mb-1" style="margin-top: -45px">
                  <profile-details
                    :flat="true"
                    :from="'ranking'"
                    :candidate_id="item.candidate.id"
                    :filter="filter"
                    :vacancy_Id="vacancy"
                    :vacancies="vacancies"
                    v-show="!showProfile"
                  />
                </v-row>
                <!-- <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  class=""
                >
                  <v-card v-show="showProfile">
                    <bar-chart :chartdata="chartData(item.tests)" />
                  </v-card>
                  <v-row class="mb-0"> 
                  <profile-details :flat="true" :candidate_id="item.candidate.id" :filter="filter" :vacancy_Id="vacancyId" v-show="!showProfile"/>
                  </v-row>
                </v-col> -->
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <note-candidate-dialog
      v-if="candidate.candidate.id"
      :candidateId="candidate.candidate"
      :dialog="noteDialog"
      :ranking="true"
      :vacancyId="vacancy"
      @close="noteDialog = false"
    />
    <review-test
      :dialog="reviewDialog"
      v-if="reviewDialog"
      :tests="tests"
      @close="reviewDialog = false"
      @fetch="vacancyFetch()"
    />
    <schedule-interview-dialog
      :dialog="dialogInterview"
      v-if="dialogInterview"
      :selectedCandidates="selected"
      :vacancyId="vacancyId"
      @close="dialogInterview = false"
    />
    <select-test
      :selectedCandidates="selected"
      :dialog="dialogTest"
      :vacancyId="vacancyId"
      @close="dialogTest = false"
      @done="dialogTestDone()"
      v-if="dialogTest"
    />
    <send-email-to-candidate
      :dialog="dialogEmail"
      :candidate="candidate.candidate"
      @close="closeAddDialog()"
      @done="sendEmailDone()"
    ></send-email-to-candidate>
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />

    <approve-reject
      :dialog="dialogRejectAprove"
      v-if="dialogRejectAprove"
      :selectedCandidates="selected"
      :vacancyId="vacancyId"
      :vacancy="this.vacancy"
      @getStage="getStage"
      @close="dialogRejectAprove = false"
      @reprove="
        dialogRejectReject = true;
        dialogRejectAprove = false;
      "
    />

    <reject-candidate-dialog
      :dialog="dialogRejectReject"
      :selectedCandidates="selected"
      :vacancyId="vacancyId"
      :vacancy="this.vacancy"
      :stage="stage"
      @close="dialogRejectReject = false"
    />

    <SuccessDialogInterview />
    <infoDialogStage />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  GET_PUBLISHED_VACANCIES_QUERY,
  GET_VACANCY_BY_ID_QUERY,
} from "../graphql/Query.resolver";
import { SOLVED_TEST_SUBSCRIPTION } from "./../graphql/Subscraption.resolver";
import { mapGetters } from "vuex";
// import BarChart from "./../components/BarChart.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import moment from "moment";
import NoteCandidateDialog from "./../components/NoteCandidateDialog.vue";
import ReviewTest from "../components/ReviewTestList.vue";
import ScheduleInterviewDialog from "./../components/ScheduleInterviewDialog";
import SelectTest from "./../components/SelectTest.vue";
import accessLevelMixins from "@/mixins/access-level";
import yearsMixins from "@/mixins/years";
import SendEmailToCandidate from "./../components/SendEmailToCandidate.vue";
import SuccessDialog from "@/modules/entity/components/SuccessDialog.vue";
import ProfileDetails from "./Profile.vue";
import SuccessDialogInterview from "../../interview/components/SuccessDialog.vue";
import infoDialogStage from "../../interview/components/infoDialog.vue";
import ApproveReject from "./../components/ApproveReject.vue";
import RejectCandidateDialog from "./../components/RejectCandidateDialog.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
// import { GET_PUBLISHED_VACANCIES_SEARCH } from "../graphql/Query.resolver";
export default {
  name: "Ranking",
  mixins: [accessLevelMixins, yearsMixins, replaceSpecialCharsMixins],

  components: {
    // BarChart,
    InfoViewDialog,
    NoteCandidateDialog,
    ReviewTest,
    ScheduleInterviewDialog,
    SelectTest,
    SendEmailToCandidate,
    SuccessDialog,
    ProfileDetails,
    SuccessDialogInterview,
    infoDialogStage,
    ApproveReject,
    RejectCandidateDialog,
  },
  data: () => ({
    dialogRejectAprove: false,
    dialogRejectReject: false,
    stage: "",
    dialogEmail: false,
    showSuccess: false,
    success: "",
    search: null,
    candidate: {
      candidate: {},
      notes: [],
    },
    candidates: [],
    dialogInterview: false,
    dialogTest: false,
    filter: {
      areasInterest: undefined,
      belongsToMany: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 4,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    infoDialog: false,
    // infoView: {
    //   icon: "mdi-account-multiple-check-outline",
    //   title: "Ranking",
    //   content:
    //     "Acompanhe o desenpenho dos candidatos aos testes submetidos, Visualize os perfis, agende entrevistas individuais e colectivas.",
    // },
    noteDialog: false,
    reviewDialog: false,
    selectAll: false,
    selectedCandidates: [],
    skipQuery: true,
    tests: [],
    textSearch: "",
    vacancyId: null,
    vacancy: {},
    vacancies: [],
    apiUrl: API_URL,
    showProfile: false,
    item_id: "",
    item_loading: false,
    loading: true,
  }),
  apollo: {
    vacancies: {
      query: GET_PUBLISHED_VACANCIES_QUERY,
      variables() {
        return {
          ranking: true,
          name: undefined,
          page: 1,
          pageSize: 7,
          vacancyId: this.$route.query.vacancyId
            ? this.$route.query.vacancyId
            : undefined,
        };
      },
      // fetchPolicy: "no-cache",
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.loading = false;
        }
      },
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId,
        };
      },
      // fetchPolicy: "no-cache",
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.loading = false;
          this.vacancy = data.vacancy;
          this.filter.ids = this.vacancy.candidacies.map((c) => c.candidate.id);
        }
      },
    },
    $subscribe: {
      solvedTest: {
        query: SOLVED_TEST_SUBSCRIPTION,
        result({ data }) {
          console.log(data.solvedTest);
          const index = this.vacancy.candidacies.findIndex(
            (c) => c.candidate.id == data.solvedTest.candidate
          );
          const testIndex = this.vacancy.candidacies[index].tests.findIndex(
            (t) => t.id == data.solvedTest.id
          );
          this.vacancy.candidacies[index].tests.splice(
            testIndex,
            1,
            data.solvedTest
          );
        },
      },
    },
  },
  watch: {
    vacancies: function (val) {
      if (!this.vacancyId) {
        this.vacancyId = val[0].id;
        this.changeVacancy();
      } else {
        this.changeVacancy();
      }
    },
    search(val) {
      if (val) {
        this.querySelections(val);
      } else {
        // this.allVacancies = [...this.vacancies];
      }
    },
  },
  created() {
    if (this.$route.query.vacancyId) {
      this.vacancyId = this.$route.query.vacancyId;
      // this.changeVacancy()
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    sendTestBtnLabel() {
      return (
        this.$t("send_test") + ": " + this.$t("tooltip_sendtest_candidate")
      );
    },
    scheduleInterviewBtnLabel() {
      return (
        this.$t("schedule_interview") + ": " + this.$t("tooltip_interview")
      );
    },
    filteredItems() {
      let resultItems = this.vacancy.candidacies
        ? this.vacancy.candidacies.map((c) => ({
            ...c,
            fullName: c.candidate.fullName,
          }))
        : [];

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter(
          (c) =>
            this.replaceSpecialChars(c.fullName).includes(text) ||
            (c.candidate.profession &&
              this.replaceSpecialChars(c.candidate.profession).includes(text))
        );
      }

      return resultItems;
    },
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photo",
          width: "1%",
        },

        {
          text: this.$t("form_candidate_name"),
          align: "start",
          sortable: true,
          value: "fullName",
          width: "28%",
        },
        {
          text: this.$t("form_candidate_job"),
          align: "start",
          sortable: true,
          value: "profession",
          width: "15%",
        },
        {
          text: this.$t("years_of_experience"),
          align: "start",
          sortable: true,
          value: "experience",
          width: "20%",
        },
        {
          text: this.$t("overallscore"),
          sortable: true,
          value: "punctuation",
          width: "20%",
        },
        {
          text: this.$t("tests"),
          align: "center",
          sortable: false,
          value: "review",
          width: "1%",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
          width: "1%",
        },
        {
          text: this.$t("Notepad"),
          align: "center",
          sortable: false,
          value: "notes",
          width: "25%",
        },
        // {
        //   text: this.$t("form_candidate_profile"),
        //   align: "center",
        //   sortable: false,
        //   // value: "profile",
        //   value: "data-table-expand",
        //   width: "1%",
        // },
        {
          text: this.$t("form_candidate_profile"),
          align: "center",
          value: "data-table-expand",
          width: "1%",
        },
      ];
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    selected() {
      return this.selectedCandidates.map((c) => {
        const candidate = Object.assign({}, c.candidate);
        delete candidate.age;
        delete candidate.entities;
        delete candidate.fullName;

        return candidate;
      });
    },
    infoView() {
      return {
        icon: "mdi-account-multiple-check-outline",
        title: "Ranking",
        content: this.$t("info_ranking"),
      };
    },
  },
  methods: {
    async searchVacancy(title) {
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_PUBLISHED_VACANCIES_QUERY,
              variables: {
                ranking: true,
                name: title,
                page: 1,
                pageSize: 10,
                vacancyId: undefined,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },
    querySelections(name) {
      this.loading = true;
      this.searchVacancy(name).then((response) => {
        console.log(response.data);
        this.vacancies = response.data.vacancies;
        this.loading = false;
      });
    },
    itemSelected(table) {
      if (table.value) {
        // this.selectedCandidates = [];
        // if (table.item.statusStage !== "reprove") {
        //   this.selectedCandidates.push(table.item);
        // } else {
        //   let successMsg = this.$t("Este candidato foi reprovado nesta vaga");
        //   // eslint-disable-next-line no-undef
        //   Fire.$emit("infoDialog", successMsg);
        // }
      }
    },
    toggleSelectAll(table) {
      if (table.value) {
        // this.selectedCandidates = [
        //   ...table.items.filter((item) => item.statusStage != "reprove"),
        // ];
        // if (
        //   table.items.filter((item) => item.statusStage === "reprove").length
        // ) {
        //   let successMsg = this.$t(
        //     "O(s) candidato(s) desmarcado(s) foi(foram) reprovado(s) nesta vaga"
        //   );
        //   // eslint-disable-next-line no-undef
        //   Fire.$emit("infoDialog", successMsg);
        // }
      }
    },
    getStage(stage) {
      this.stage = stage.name;
    },
    async changeVacancy() {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      this.filter.ids = this.vacancies[index].candidacies.map(
        (c) => c.candidate
      );
      this.$apollo.queries.vacancy.skip = false;
      this.selectedCandidates = [];
      await this.$apollo.queries.vacancy.refetch();
    },

    calculePontuation(tests) {
      let total = tests.length;
      let totaScoreObsrvation = 0;
      let result =
        tests.reduce((accumulator, item) => {
          if (!accumulator) {
            accumulator = 0;
          }
          if (!item.result) {
            item.result = 0;
          }
          if (item.type == "psychological" || item.type == "technician") {
            console.log(item.type, item.questions);
            let cal = item.questions.find((q) => q.openQuestion === true);
            if (cal) {
              // console.log(cal);
              if (!item.stage) {
                totaScoreObsrvation = cal.observations.length
                  ? cal.observations
                      .map((obs) => {
                        return obs.result;
                      })
                      .map(Number)
                      .reduce((acc, curr) => acc + curr, 0)
                  : 0;
              } else {
                item.questions.forEach((q) => {
                  if (item.questions.length) {
                    // if (q.openQuestion === true) {
                    q.observations.forEach((element) => {
                      totaScoreObsrvation += parseFloat(element.result);
                    });
                    // }
                  }
                });
              }
              console.log(
                parseFloat(totaScoreObsrvation),
                parseFloat(item.result),
                parseFloat(accumulator)
              );
              // if (cal.observations.length) {
              //   totaScoreObsrvation = totaScoreObsrvation / 4;
              // }
              return (
                parseFloat(accumulator) +
                parseFloat(item.result) +
                parseFloat(totaScoreObsrvation)
              );
            } else {
              // total = total - 1;
              console.log("tecn", parseFloat(accumulator));
              return parseFloat(accumulator) + parseFloat(item.result);
            }
          } else if (item.result) {
            return accumulator + item.result;
          } else if (!this.days_(item)) {
            return accumulator + 0;
          } else {
            total = total - 1;
            return accumulator + 0;
          }
        }, 0) / total;
      console.log(result);
      return result >= 0 ? Number(result.toFixed(2)) : 0;
    },
    chartData(tests) {
      let labels = [],
        data = [];

      if (tests) {
        for (let index = 0; index < tests.length; index++) {
          labels.push(tests[index].title);
          if (tests[index].result) {
            data.push(tests[index].result);
          } else {
            if (tests[index].type == "psychological") {
              let cal = tests[index].questions.find(
                (q) => q.openQuestion === true
              );
              if (cal) {
                let totaScoreObsrvation = cal.observations
                  ? cal.observations
                      .map((obs) => {
                        return obs.result;
                      })
                      .map(Number)
                      .reduce((acc, curr) => acc + curr, 0)
                  : 0;
                if (cal.observations.length) {
                  totaScoreObsrvation =
                    totaScoreObsrvation / cal.observations.length;
                }
                data.push(totaScoreObsrvation);
              }
            } else {
              data.push("pontuação Manual");
            }
          }
        }
      } else {
        labels.push(1);
      }

      const datasets = [
        {
          label: this.$t("overallscore"),
          backgroundColor: this.setColorToChart(data),
          borderColor: this.setBorderColorToChart(data),
          borderWidth: 1,
          data,
        },
      ];
      return { labels, datasets };
    },
    days_(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
    dialogTestDone() {
      this.dialogTest = false;
      this.changeVacancy();
    },
    go(candidateId) {
      this.$router.push({
        path: `/dashboard/entity/ranking/profile/${candidateId}`,
        query: {
          filter: this.filter,
          from: "ranking",
          vacancyId: this.vacancyId,
        },
      });
    },
    openNoteDialog(candidate) {
      this.candidate = candidate;
      this.noteDialog = true;
    },
    async vacancyFetch() {
      this.item_loading = true;
      // this.$apollo.queries.vacancy.skip = true;
      await this.$apollo.queries.vacancy.refetch();
      this.item_loading = false;
    },
    openDialogEmail(candidate) {
      this.candidate = candidate;
      this.dialogEmail = true;
    },
    closeAddDialog() {
      this.show = false;
      this.dialogEmail = false;
    },
    sendEmailDone() {
      this.closeAddDialog();
      this.success = this.$t("email_sent_successfully");
      this.showSuccess = true;
    },
    reviewTest(tests) {
      this.tests = this.removeDuplicates(tests);
      this.reviewDialog = true;
    },
    removeDuplicates(array) {
      return array.filter(
        (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
      );
    },
    setColor(punctuation) {
      if (punctuation < 50) {
        return "red";
      } else if (punctuation < 70) {
        return "#FFC107";
      } else {
        return "green";
      }
    },
    setBorderColorToChart(values) {
      let data = [];

      data = values.map((v) => {
        if (Math.ceil(v) < 50) {
          return "rgb(244, 67, 54, 1)";
        } else if (Math.ceil(v) < 70) {
          return "rgb(255, 193, 7, 1)";
        } else {
          return "rgb(76, 175, 80, 1)";
        }
      });

      return data;
    },
    setColorToChart(values) {
      let data = [];

      data = values.map((v) => {
        if (Math.ceil(v) < 50) {
          return "rgb(244, 67, 54, 0.2)";
        } else if (Math.ceil(v) < 70) {
          return "rgb(255, 193, 7, 0.2)";
        } else {
          return "rgb(76, 175, 80, 0.2)";
        }
      });

      return data;
    },
  },
};
</script>
